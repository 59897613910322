<template>
  <div class="sweepstakes_wrap">
    <h1>伟业计量2021开工有礼</h1>
    <div class="sweepstakes_title">
      <div class="sweepstakes_title_unpack">回答问题即可开箱</div>
      <div class="sweepstakes_guan">
        我们的官网是？www.<input
          type="text"
          name="name"
          id="Q_answer"
          class="sweepstakes_input"
          maxlength="4"
        />.com，其中bzwz是
        <select class="challengeWhellSelect">
          <option value="kzyz"></option>
          <option value="pzyz">被子袜子</option>
          <option value="yzyz">杯子包子</option>
          <option value="bzwz">标准物质</option></select
        >的拼音首字母
      </div>
    </div>
    <div class="sweepstakes_content">
      <div class="wrapper open-body">
        <div class="bgbody rotate"></div>
        <div class="open-has">
          <h3 class="title-close"></h3>
          <h3 class="title-open">
            恭喜你，<br />成功获取<span class="user userText">100代金券</span>
          </h3>
          <div class="mod-chest">
            <div class="chest-close show chest-onclose">
              <div class="gift gift_unpacking"></div>
              <div class="tips">
                <i class="arrow"></i>
              </div>
            </div>
            <div class="chest-open">
              <div class="mod-chest-cont open-cont">
                <div class="content">
                  
                  <div class="gift">
                    <div class="icon">
                      <img
                        class="chest_zuan"
                        src="https://www.bzwz.com/static/pc/img/unpacking/waveoven.png"
                      />
                    </div>
                  </div>
                  <div class="func">
                    <router-link :to="{ name: 'Message' }">
                      <button class="chest-btn">查看详情</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="open-none" style="display: none">
          <h3>你来晚啦，下次早点吧！</h3>
          <div class="mod-chest">
            <div class="chest-open show"></div>
          </div>
          <div class="func">
            <button class="chest-btn">查看领取详情</button>
          </div>
        </div>
      </div>
    </div>
    <div class="sweepstakes_bottom">
      <div class="Sweepstakes_guize">
        <span class="Sweepstakes_guize_title" style="margin-top: 15px"
          >活动规则</span
        >
        <div class="Sweepstakes_guize_content">
          <p style="margin-top: 10px">1、本次活动对象仅限伟业计量客户；</p>
          <p>2、活动时间：2022年02月19日——2022年02月26日。</p>
          <p>
            3、活动期间，每人共有两次抽奖机会，伟业官网PC一次，关注微信公众号可以再抽一次
          </p>
          <p>4、特殊时期发件会延期，快递正常后会第一时间寄出，请耐心等待。</p>
          <p>注：本活动最终解释权归伟业计量所有</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted } from "vue";
import $ from "jquery";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import { useRouter } from "vue-router";

import qs from "qs";
export default defineComponent({
  name: "StartJob",

  setup() {
    const imgArr = [
      {
        img: require("../assets/img/start-job/weibo.png"),
        width: "145px",
        top: "-45px",
        left: "-5px",
        text: "美的微波炉",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/integral.png",
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分18888",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/integral.png",
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分8888",
      },
      {
        img: require("../assets/img/start-job/guangpan.png"),
        width: "80px",
        top: "-50px",
        left: "20px",
        text: "伟业计量培训中心课程光盘/个",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/integral.png",
     width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分3888",
      },
      {
        img: require("../assets/img/start-job/lipin.png"),
        width: "120px",
        top: "-78px",
        left: "0",
        text: "伟业数据线",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/coupon.png",
        width: "120px",
        top: "-78px",
        left: "0",
        text: "100元代金券",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/integral.png",
       width: "80px",
        top: "-49px",
        left: "24px",
        text: "888积分",
      },
    ];
    let chestCloseTimer: number | undefined;
    const router = useRouter();

    onMounted(() => {
      nextTick(() => {
        // 抽奖
        $("#Q_answer").blur(function () {
          const answer = ($("#Q_answer").val() as any).trim().toLowerCase();
          if (answer == "") {
            Toast({
              title: "要先输入网址才能抽奖哦",
              duration: 2000,
              type: "warning",
            });

            $(this).focus();
            return false;
          }
          if (answer != "bzwz") {
            Toast({
              title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
              type: "warning",
              duration: 2000,
            });

            $(this).focus();
            return false;
          }
        });
        $(".challengeWhellSelect").blur(function () {
          const challengeWhellSelect = $(".challengeWhellSelect").val();
          if (challengeWhellSelect != "bzwz") {
            //layer.msg("选择错误。bzwz是“标准物质”的拼音首字母", { time: 1500 });
            Toast({
              title: "选择错误。bzwz是“标准物质”的拼音首字母",
              type: "warning",
              duration: 2000,
            });

            return false;
          }
        });

        $(".chest-onclose").click(function () {
  
          const token = localStorage.getItem("token");
          if (!token) {
            Modal({
              title: "温馨提示",
              content: "登录后才可以进行抽奖哦~",
              onClose: () => {
                router.push({
                  name: "Login",
                  query: {
                    redirect: router.currentRoute.value.fullPath,
                  },
                });
              },
            });
            return false;
          }
          const answer = ($("#Q_answer").val() as any).trim().toLowerCase();

          const challengeWhellSelect = $(".challengeWhellSelect").val();
          if (answer == "") {
            Toast({
              title: "要先输入网址才能抽奖哦",
              type: "warning",
              duration: 2000,
            });

            $("#Q_answer").focus();
            return false;
          }
          if (answer != "bzwz") {
            Toast({
              title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
              type: "warning",
              duration: 2000,
            });
            $("#Q_answer").focus();
            return false;
          }
          if (challengeWhellSelect != "bzwz") {
            Toast({
              title: "选择错误。bzwz是“标准物质”的拼音首字母",
              type: "warning",
              duration: 2000,
            });
            return false;
          }
          const that = $(".chest-onclose").get(0);

          axios
            .post(
              "/M/Event/StartWork",
              qs.stringify({
                id: 12,
                tag: 1,
              })
            )
            .then((res: any) => {
              if (res.data.success) {
                $(that).addClass("shakecopy");
                let imgUrl = {};
                for (let i = 0; i < imgArr.length; i++) {
                  if (res.data.msg.indexOf(imgArr[i].text) != -1) {
                    imgUrl = imgArr[i];
                  }
                }
                $(".userText").text(res.data.msg);
                $(".chest_zuan").css({
                  width: (imgUrl as any).width,
                  top: (imgUrl as any).top,
                  left: (imgUrl as any).left,
                });
                $(".chest_zuan").attr("src", (imgUrl as any).img);
                that.addEventListener(
                  "webkitAnimationEnd",
                  function () {
                    $(that).closest(".open-has").addClass("opened");
                    setTimeout(function () {
                      $(that).removeClass("show");
                      $(that).closest(".mod-chest").find(".chest-open").addClass("show");
                      setTimeout(function () {
                        $(".chest-open").addClass("blur");
                      }, 500);
                    }, 200);
                  },
                  false
                );
              } else if (res.data.existAddress === false) {
                Modal({
                  title: "提示",
                  content: "系统找不到默认地址，如果没有设置默认地址，请先设置",
                  onConfirm: () => {
                    router.push({
                      name: "addAddress",
                    });
                  },
                });
              } else {
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
            })
            .catch((err) => {
              Toast({
                type: "error",
                title: "执行出错，请联系管理员！",
              });
            });
        });

        $(".close-unpacking-img").click(function () {
          clearTimeout(chestCloseTimer);
          $(".chest-close").removeClass("shakecopy");
          $(".open-has").removeClass("opened");
          $(".chest-open").removeClass("show");
          $(".chest-close").addClass("show");
          $(".chest-open").removeClass("blur");
        });
      });
    });
  },
});
</script>

<style lang="scss">
/*button*/

.c-orange {
  color: #ff5400;
}

.c-red {
  color: #cd0000;
}

.c-gray {
  color: rgba(134, 85, 0, 0.7);
}

.chest-btn {
 width: 100%;
    display: block;
    color: #f42626;
    background: #ffa200;
    height: 1.17333rem;
    line-height: 1.17333rem;
    border-radius: 0.08rem;
    font-size: 0.45333rem;
    outline: none;
    border: none;
}

/*.chest-btn:active {
        background: #f09902;
    }*/
.chest-detail-btn {
  background: #ffa200;
}

.btn-weak {
  background: #ff9600;
}

.btn-weak:active {
  background: #ec8c03;
}

.wrapper .ui-border-top {
  border-top: 1px solid #eccf88;
}

.wrapper .ui-border-btm {
  border-bottom: 1px solid #eccf88;
}

.wrapper .ui-border-tb {
  border-top: #eccf88 1px solid;
  border-bottom: #eccf88 1px solid;
  background-image: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .wrapper .ui-border-top {
    border-top: 0;
  }

  .wrapper .ui-border-btm {
    border-bottom: 0;
  }

  .wrapper .ui-border-top {
    background-position: left top;
    background-image: gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.5, rgba(0, 0, 0, 0)),
      color-stop(0.5, #eccf88),
      to(#eccf88)
    );
    background-image: -moz-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.5, rgba(0, 0, 0, 0)),
      color-stop(0.5, #eccf88),
      to(#eccf88)
    );
    background-image: -o-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.5, rgba(0, 0, 0, 0)),
      color-stop(0.5, #eccf88),
      to(#eccf88)
    );
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.5, rgba(0, 0, 0, 0)),
      color-stop(0.5, #eccf88),
      to(#eccf88)
    );
  }

  .wrapper .ui-border-btm {
    background-position: left bottom;
    background-image: gradient(
      linear,
      left top,
      left bottom,
      color-stop(0.5, rgba(0, 0, 0, 0)),
      color-stop(0.5, #eccf88),
      to(#eccf88)
    );
    background-image: -moz-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0.5, rgba(0, 0, 0, 0)),
      color-stop(0.5, #eccf88),
      to(#eccf88)
    );
    background-image: -o-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0.5, rgba(0, 0, 0, 0)),
      color-stop(0.5, #eccf88),
      to(#eccf88)
    );
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0.5, rgba(0, 0, 0, 0)),
      color-stop(0.5, #eccf88),
      to(#eccf88)
    );
  }

  .wrapper .ui-border-top,
  .wrapper .ui-border-btm {
    background-repeat: repeat-x;
    background-size: 100% 1px;
  }

  .wrapper .ui-border-tb {
    background: -webkit-gradient(
          linear,
          left bottom,
          left top,
          color-stop(0.5, transparent),
          color-stop(0.5, #eccf88),
          to(#eccf88)
        )
        left top repeat-x,
      -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(0.5, transparent),
          color-stop(0.5, #eccf88),
          to(#eccf88)
        ) left bottom repeat-x;
    border-top: 0;
    border-bottom: 0;
  }

  .wrapper .ui-border-tb {
    background-size: 100% 1px;
    -wekit-background-size: 100% 1px;
  }
}

.mod-chest-cont {
  background: url(https://www.bzwz.com/static/pc/img/unpacking/open-bg-top.png)
      center top no-repeat,
    url(https://www.bzwz.com/static/pc/img/unpacking/open-bg-bottom.png) center
      bottom no-repeat;
  background-size: 276px auto;
  width: 276px;
  margin: 27px auto 118px;
  position: relative;
}

.mod-chest-cont:before {
  width: 100%;
  position: absolute;
  top: 12px;
  bottom: 80px;
  left: 0px;
  content: "";
  background: #fff;
  border-left: #e59c00 1px solid;
  border-right: #e59c00 1px solid;
  box-sizing: border-box;
}

.mod-chest-cont .content {
  position: relative;
  z-index: 2;
  padding: 13px;
}

.mod-chest-cont h3 {
  color: #865500;
  font-size: 15px;
}

.mod-chest {
  position: relative;
  width: 100%;
}

.mod-chest .chest-close {
  width: 320px;
  height: 135px;
  opacity: 0;
  position: relative;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 0px;
  margin-left: -160px;
}

.mod-chest .chest-close .gift {
  width: 320px;
  height: 135px;
  background: url(https://www.bzwz.com/static/pc/img/unpacking/chest.png)
    no-repeat 0px 0px;
  background-size: 320px auto;
  position: absolute;
  left: 0;
  top: 0px;
}

.mod-chest .chest-close .gift_unpacking {
  background: url(https://www.bzwz.com/static/pc/img/unpacking/chest_unpacking.png)
    no-repeat 0px 0px;
  background-size: 320px auto;
}

.mod-chest .chest-open {
  width: 320px;
  height: 150px;
  background: url(https://www.bzwz.com/static/pc/img/unpacking/chest.png)
    no-repeat 0px -137px;
  background-size: 320px auto;
  opacity: 0;
  position: relative;
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 0px;
}

.mod-chest .chest-open .mod-chest-cont {
  position: absolute;
  bottom: 44px;
  left: 50%;
  margin-left: -138px;
}

.mod-chest .show {
  z-index: 2;
  opacity: 1;
}

.chest-icon-zuan {
  position: relative;
  width: 50px;
  overflow: visible;
  height: 0px;
  vertical-align: middle;
  display: inline-block;
}

.chest-icon-zuan:after {
  display: block;
  content: "";
  width: 75px;
  height: 50px;
  position: absolute;
  top: -30px;
  left: 0px;
  background: url(https://www.bzwz.com/static/pc/img/unpacking/chest-icon-zuan.png)
    0px 0px no-repeat;
  background-size: 75px auto;
  left: -10px;
}

.icon-state-doing,
.icon-state-finish,
.icon-state-expired {
  display: block;
  width: 112px;
  height: 45px;
  background: url(https://www.bzwz.com/static/pc/img/unpacking/chest-state.png)
    0px 0px no-repeat;
  background-size: 112px auto;
}

.icon-state-doing {
  background-position: 0px -45px;
}

.icon-state-expired {
  background-position: 0px -90px;
}

.icon-gift-xin,
.icon-gift-zuan {
  position: relative;
  width: 15px;
  overflow: visible;
  height: 0px;
  vertical-align: middle;
  display: inline-block;
  margin: 0px 3px;
}

.icon-gift-xin:after,
.icon-gift-zuan:after {
  display: block;
  content: "";
  width: 15px;
  height: 13px;
  position: absolute;
  top: -8px;
  left: 0px;
  background: url(https://www.bzwz.com/static/pc/img/unpacking/chest-icon-sprite.png)
    0px 0px no-repeat;
  background-size: 100px auto;
}

.icon-gift-xin:after {
  background-position: -20px 0px;
}

.icon-dot {
  width: 6px;
  height: 6px;
  display: inline-block;
  overflow: hidden;
  background: #ff0000;
  border-radius: 6px;
}

.ui-round {
  mask: url(https://www.bzwz.com/static/pc/img/unpacking/radius-mask.png) 0px
    0px no-repeat;
  -moz-mask: url(https://www.bzwz.com/static/pc/img/unpacking/radius-mask.png)
    0px 0px no-repeat;
  -o-mask: url(https://www.bzwz.com/static/pc/img/unpacking/radius-mask.png) 0px
    0px no-repeat;
  -webkit-mask: url(https://www.bzwz.com/static/pc/img/unpacking/radius-mask.png)
    0px 0px no-repeat;
  mask-size: 100% auto;
  -moz-mask-size: 100% auto;
  -o-mask-size: 100% auto;
  -webkit-mask-size: 100% auto;
}

.wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.open-body {
  width: 100%;
  overflow: hidden;
}

.open-body .bgbody {
  position: absolute;
  z-index: 1;
  background: url(https://www.bzwz.com/static/pc/img/unpacking/light.png) 0px
    0px no-repeat;
  background-size: 452px auto;
  width: 452px;
  height: 510px;
  max-height: 100%;
  left: 50%;
  margin-left: -226px;
  top: -25px;
}

.open-body .bgbody.rotate {
  -webkit-animation: rotate 10s infinite linear;
  -ms-animation: rotate 10s infinite linear;
  -moz-animation: rotate 10s infinite linear;
  -o-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
}

.open-has {
  position: relative;
  z-index: 2;
  height: 450px;
  width: 320px;
  margin: 0px auto;
}

.open-has .title-close {
  opacity: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  text-align: center;
}

.open-has .title-open {
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
  top: -90px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 16px;
}

.open-has h3 {
  color: #865500;
  /*font-size: 15px;*/
  font-size: 18px;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 33px;
}

.open-has h3 .user {
  color: #ff5400;
  margin: 0px 5px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  font-size: 18px;
}

.open-has .title-close .user {
}

.mod-chest .chest-close .tips {
  width: 166px;
  height: 60px;
  background: url(https://www.bzwz.com/static/pc/img/unpacking/txt-tips-open.png)
    0px 0px no-repeat;
  background-size: 190px auto;
  position: absolute;
  left: 70px;
  top: 145px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mod-chest .chest-close .tips_unpacking {
  width: 166px;
  height: 60px;
  background: url(https://www.bzwz.com/static/pc/img/unpacking/txt-tips-open-unpackingdetail.png)
    0px 0px no-repeat;
  background-size: 190px auto;
  position: absolute;
  left: 70px;
  top: 145px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mod-chest .chest-close .tips .arrow {
  width: 26px;
  height: 60px;
  background: url(https://www.bzwz.com/static/pc/img/unpacking/txt-tips-open.png)
    right 0px no-repeat;
  background-size: 190px auto;
  position: absolute;
  display: block;
  top: 0px;
  right: -28px;
  -webkit-animation: move 0.7s linear infinite alternate;
  -ms-animation: move 0.7s linear infinite alternate;
  -moz-animation: move 0.7s linear infinite alternate;
  -o-animation: move 0.7s linear infinite alternate;
  animation: move 0.7s linear infinite alternate;
}

.mod-chest .chest-close:after {
  width: 320px;
  height: 300px;
  position: absolute;
  top: -100px;
  left: -61px;
  content: "";
  display: block;
}

.mod-chest .chest-close:active {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.mod-chest .chest-close.shakecopy .gift {
  -webkit-animation: shake 1.2s linear;
  -ms-animation: shake 1.2s linear;
  -moz-animation: shake 1.2s linear;
  -o-animation: shake 1.2s linear;
  animation: shake 1.2s linear;
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.mod-chest .chest-close.shakecopy .tips {
  opacity: 0;
}

.mod-chest .chest-open {
}

/*.mod-chest .chest-open.blur:after {
    width: 320px;
    height: 68px;
    background: url(https://www.bzwz.com/static/pc/img/unpacking/chest-open-blur.png) 0px 0px no-repeat;
    background-size: 320px auto;
    position: relative;
    z-index: 1;
    position: absolute;
    left: 0px;
    top: -5px;
    content: "";
    display: block;
}
*/

.open-has.opened .title-open {
  opacity: 1;
  font-size: 18px;
}

.open-has.opened .title-close {
  opacity: 0;
}

/*.open-has.opened .mod-chest{-webkit-transform:translate(0px,150px);}*/
.open-has.opened .mod-chest .chest-open {
  -webkit-transform: translate(0px, 150px);
  -ms-transform: translate(0px, 150px);
  -moz-transform: translate(0px, 150px);
  -o-transform: translate(0px, 150px);
  transform: translate(0px, 150px);
}

.open-has .mod-chest-cont {
  padding: 30px 0px 15px;
  text-align: center;
  overflow: hidden;
  opacity: 0;
  -webkit-transform: translate(0px, 30px);
  -ms-transform: translate(0px, 30px);
  -moz-transform: translate(0px, 30px);
  -o-transform: translate(0px, 30px);
  transform: translate(0px, 30px);
  /*-webkit-transition:all .3s .3s;-webkit-transition:all .3s .3s cubic-bezier(.53,1.5,.69,1.81);*/
  z-index: 9;
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.open-has .chest-open.show .mod-chest-cont {
  /*-webkit-transform:translate(0px,0px);opacity: 1;*/
  -webkit-animation: move2 0.2s 0s linear;
  -ms-animation: move2 0.2s 0s linear;
  -moz-animation: move2 0.2s 0s linear;
  -o-animation: move2 0.2s 0s linear;
  animation: move2 0.2s 0s linear;
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.open-has .mod-chest-cont .gift {
  color: #ff6000;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 30px;
}

.open-has .mod-chest-cont .gift .icon {
  width: 120px;
  height: 0px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.open-has .mod-chest-cont .gift img {
  width: 75px;
  position: absolute;
  top: -28px;
  left: -5px;
}

.open-none {
  position: relative;
  z-index: 2;
  height: 450px;
  width: 320px;
  margin: 0px auto;
}

.open-none .mod-chest {
  height: 150px;
}

.open-none h3 {
  font-size: 15px;
  color: #865500;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 40px;
}

.open-none .func {
  padding: 18px 35px 30px;
}

.chest-open .func {
  position: relative;
  z-index: 10;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@-o-keyframes rotate {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
  }

  100% {
    transform: translate(0px, -5px);
    -ms-transform: translate(0px, -5px);
    -moz-transform: translate(0px, -5px);
    -webkit-transform: translate(0px, -5px);
    -o-transform: translate(0px, -5px);
  }
}

@-webkit-keyframes move {
  0% {
    transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
  }

  100% {
    transform: translate(0px, -5px);
    -ms-transform: translate(0px, -5px);
    -moz-transform: translate(0px, -5px);
    -webkit-transform: translate(0px, -5px);
    -o-transform: translate(0px, -5px);
  }
}

@-moz-keyframes move {
  0% {
    transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
  }

  100% {
    transform: translate(0px, -5px);
    -ms-transform: translate(0px, -5px);
    -moz-transform: translate(0px, -5px);
    -webkit-transform: translate(0px, -5px);
    -o-transform: translate(0px, -5px);
  }
}

@-o-keyframes move {
  0% {
    transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
  }

  100% {
    transform: translate(0px, -5px);
    -ms-transform: translate(0px, -5px);
    -moz-transform: translate(0px, -5px);
    -webkit-transform: translate(0px, -5px);
    -o-transform: translate(0px, -5px);
  }
}

@keyframes move2 {
  0% {
    transform: translate(0px, 30px);
    -ms-transform: translate(0px, 30px);
    -moz-transform: translate(0px, 30px);
    -webkit-transform: translate(0px, 30px);
    -o-transform: translate(0px, 30px);
    opacity: 0;
  }

  70% {
    transform: translate(0px, -20px);
    -ms-transform: translate(0px, -20px);
    -moz-transform: translate(0px, -20px);
    -webkit-transform: translate(0px, -20px);
    -o-transform: translate(0px, -20px);
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    opacity: 1;
  }
}

@-webkit-keyframes move2 {
  0% {
    transform: translate(0px, 30px);
    -ms-transform: translate(0px, 30px);
    -moz-transform: translate(0px, 30px);
    -webkit-transform: translate(0px, 30px);
    -o-transform: translate(0px, 30px);
    opacity: 0;
  }

  70% {
    transform: translate(0px, -20px);
    -ms-transform: translate(0px, -20px);
    -moz-transform: translate(0px, -20px);
    -webkit-transform: translate(0px, -20px);
    -o-transform: translate(0px, -20px);
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    opacity: 1;
  }
}

@-moz-keyframes move2 {
  0% {
    transform: translate(0px, 30px);
    -ms-transform: translate(0px, 30px);
    -moz-transform: translate(0px, 30px);
    -webkit-transform: translate(0px, 30px);
    -o-transform: translate(0px, 30px);
    opacity: 0;
  }

  70% {
    transform: translate(0px, -20px);
    -ms-transform: translate(0px, -20px);
    -moz-transform: translate(0px, -20px);
    -webkit-transform: translate(0px, -20px);
    -o-transform: translate(0px, -20px);
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    opacity: 1;
  }
}

@-o-keyframes move2 {
  0% {
    transform: translate(0px, 30px);
    -ms-transform: translate(0px, 30px);
    -moz-transform: translate(0px, 30px);
    -webkit-transform: translate(0px, 30px);
    -o-transform: translate(0px, 30px);
    opacity: 0;
  }

  70% {
    transform: translate(0px, -20px);
    -ms-transform: translate(0px, -20px);
    -moz-transform: translate(0px, -20px);
    -webkit-transform: translate(0px, -20px);
    -o-transform: translate(0px, -20px);
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  6% {
    transform: scale(0.9) rotate(-8deg);
    -ms-transform: scale(0.9) rotate(-8deg);
    -moz-transform: scale(0.9) rotate(-8deg);
    -webkit-transform: scale(0.9) rotate(-8deg);
    -o-transform: scale(0.9) rotate(-8deg);
    transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -ms-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -moz-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -webkit-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -o-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
  }

  18%,
  30%,
  42% {
    transform: scale(1.1) rotate(8deg);
    -ms-transform: scale(1.1) rotate(8deg);
    -moz-transform: scale(1.1) rotate(8deg);
    -webkit-transform: scale(1.1) rotate(8deg);
    -o-transform: scale(1.1) rotate(8deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -moz-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -o-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
  }

  12%,
  24%,
  36%,
  48% {
    transform: scale(1.1) rotate(-8deg);
    -ms-transform: scale(1.1) rotate(-8deg);
    -moz-transform: scale(1.1) rotate(-8deg);
    -webkit-transform: scale(1.1) rotate(-8deg);
    -o-transform: scale(1.1) rotate(-8deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -moz-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -o-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
  }

  54% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  60% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale(1) translate(0px, 150px);
    -ms-transform: scale(1) translate(0px, 150px);
    -moz-transform: scale(1) translate(0px, 150px);
    -webkit-transform: scale(1) translate(0px, 150px);
    -o-transform: scale(1) translate(0px, 150px);
    transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
  }

  90% {
    transform: scale(1) translate(0px, 130px);
    -ms-transform: scale(1) translate(0px, 130px);
    -moz-transform: scale(1) translate(0px, 130px);
    -webkit-transform: scale(1) translate(0px, 130px);
    -o-transform: scale(1) translate(0px, 130px);
    transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
  }

  100% {
    transform: scale(1) translate(0px, 150px);
    -ms-transform: scale(1) translate(0px, 150px);
    -moz-transform: scale(1) translate(0px, 150px);
    -webkit-transform: scale(1) translate(0px, 150px);
    -o-transform: scale(1) translate(0px, 150px);
    transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
  }
}

@-webkit-keyframes shake {
  0% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  6% {
    transform: scale(0.9) rotate(-8deg);
    -ms-transform: scale(0.9) rotate(-8deg);
    -moz-transform: scale(0.9) rotate(-8deg);
    -webkit-transform: scale(0.9) rotate(-8deg);
    -o-transform: scale(0.9) rotate(-8deg);
    transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -ms-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -moz-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -webkit-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -o-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
  }

  18%,
  30%,
  42% {
    transform: scale(1.1) rotate(8deg);
    -ms-transform: scale(1.1) rotate(8deg);
    -moz-transform: scale(1.1) rotate(8deg);
    -webkit-transform: scale(1.1) rotate(8deg);
    -o-transform: scale(1.1) rotate(8deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -moz-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -o-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
  }

  12%,
  24%,
  36%,
  48% {
    transform: scale(1.1) rotate(-8deg);
    -ms-transform: scale(1.1) rotate(-8deg);
    -moz-transform: scale(1.1) rotate(-8deg);
    -webkit-transform: scale(1.1) rotate(-8deg);
    -o-transform: scale(1.1) rotate(-8deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -moz-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -o-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
  }

  54% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  60% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale(1) translate(0px, 150px);
    -ms-transform: scale(1) translate(0px, 150px);
    -moz-transform: scale(1) translate(0px, 150px);
    -webkit-transform: scale(1) translate(0px, 150px);
    -o-transform: scale(1) translate(0px, 150px);
    transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
  }

  90% {
    transform: scale(1) translate(0px, 130px);
    -ms-transform: scale(1) translate(0px, 130px);
    -moz-transform: scale(1) translate(0px, 130px);
    -webkit-transform: scale(1) translate(0px, 130px);
    -o-transform: scale(1) translate(0px, 130px);
    transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
  }

  100% {
    transform: scale(1) translate(0px, 150px);
    -ms-transform: scale(1) translate(0px, 150px);
    -moz-transform: scale(1) translate(0px, 150px);
    -webkit-transform: scale(1) translate(0px, 150px);
    -o-transform: scale(1) translate(0px, 150px);
    transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
  }
}

@-moz-keyframes shake {
  0% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  6% {
    transform: scale(0.9) rotate(-8deg);
    -ms-transform: scale(0.9) rotate(-8deg);
    -moz-transform: scale(0.9) rotate(-8deg);
    -webkit-transform: scale(0.9) rotate(-8deg);
    -o-transform: scale(0.9) rotate(-8deg);
    transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -ms-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -moz-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -webkit-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -o-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
  }

  18%,
  30%,
  42% {
    transform: scale(1.1) rotate(8deg);
    -ms-transform: scale(1.1) rotate(8deg);
    -moz-transform: scale(1.1) rotate(8deg);
    -webkit-transform: scale(1.1) rotate(8deg);
    -o-transform: scale(1.1) rotate(8deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -moz-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -o-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
  }

  12%,
  24%,
  36%,
  48% {
    transform: scale(1.1) rotate(-8deg);
    -ms-transform: scale(1.1) rotate(-8deg);
    -moz-transform: scale(1.1) rotate(-8deg);
    -webkit-transform: scale(1.1) rotate(-8deg);
    -o-transform: scale(1.1) rotate(-8deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -moz-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -o-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
  }

  54% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  60% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale(1) translate(0px, 150px);
    -ms-transform: scale(1) translate(0px, 150px);
    -moz-transform: scale(1) translate(0px, 150px);
    -webkit-transform: scale(1) translate(0px, 150px);
    -o-transform: scale(1) translate(0px, 150px);
    transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
  }

  90% {
    transform: scale(1) translate(0px, 130px);
    -ms-transform: scale(1) translate(0px, 130px);
    -moz-transform: scale(1) translate(0px, 130px);
    -webkit-transform: scale(1) translate(0px, 130px);
    -o-transform: scale(1) translate(0px, 130px);
    transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
  }

  100% {
    transform: scale(1) translate(0px, 150px);
    -ms-transform: scale(1) translate(0px, 150px);
    -moz-transform: scale(1) translate(0px, 150px);
    -webkit-transform: scale(1) translate(0px, 150px);
    -o-transform: scale(1) translate(0px, 150px);
    transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
  }
}

@-o-keyframes shake {
  0% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  6% {
    transform: scale(0.9) rotate(-8deg);
    -ms-transform: scale(0.9) rotate(-8deg);
    -moz-transform: scale(0.9) rotate(-8deg);
    -webkit-transform: scale(0.9) rotate(-8deg);
    -o-transform: scale(0.9) rotate(-8deg);
    transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -ms-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -moz-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -webkit-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
    -o-transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
  }

  18%,
  30%,
  42% {
    transform: scale(1.1) rotate(8deg);
    -ms-transform: scale(1.1) rotate(8deg);
    -moz-transform: scale(1.1) rotate(8deg);
    -webkit-transform: scale(1.1) rotate(8deg);
    -o-transform: scale(1.1) rotate(8deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -moz-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
    -o-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
  }

  12%,
  24%,
  36%,
  48% {
    transform: scale(1.1) rotate(-8deg);
    -ms-transform: scale(1.1) rotate(-8deg);
    -moz-transform: scale(1.1) rotate(-8deg);
    -webkit-transform: scale(1.1) rotate(-8deg);
    -o-transform: scale(1.1) rotate(-8deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -moz-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
    -o-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
  }

  54% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  60% {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale(1) translate(0px, 150px);
    -ms-transform: scale(1) translate(0px, 150px);
    -moz-transform: scale(1) translate(0px, 150px);
    -webkit-transform: scale(1) translate(0px, 150px);
    -o-transform: scale(1) translate(0px, 150px);
    transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
  }

  90% {
    transform: scale(1) translate(0px, 130px);
    -ms-transform: scale(1) translate(0px, 130px);
    -moz-transform: scale(1) translate(0px, 130px);
    -webkit-transform: scale(1) translate(0px, 130px);
    -o-transform: scale(1) translate(0px, 130px);
    transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 130px, 0);
  }

  100% {
    transform: scale(1) translate(0px, 150px);
    -ms-transform: scale(1) translate(0px, 150px);
    -moz-transform: scale(1) translate(0px, 150px);
    -webkit-transform: scale(1) translate(0px, 150px);
    -o-transform: scale(1) translate(0px, 150px);
    transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -moz-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0px, 150px, 0);
  }
}

.chest-notice {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  color: #ff6000;
  padding-bottom: 10px;
}

.chest-notice input {
  position: relative;
  top: 3px;
}

.close-unpacking-img {
  position: absolute;
  width: 20px;
  right: 11px;
  top: -19px;
  cursor: pointer;
}

.sweepstakes_wrap {
  padding: 10px;
  color: #666666;
}

.sweepstakes_wrap h1 {
  text-align: center;
  line-height: 40px;
  color: #666666;
  font-size: 24px;
}

.sweepstakes_title {
  margin-top: 10px;
}

.sweepstakes_title textarea {
  width: 100%;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #808080;
}

.sweepstakes_title .sweepstakes_title_unpack,
.Sweepstakes_guize_title {
  font-size: 16.5px;
  font-weight: bold;
  position: relative;
  padding-left: 10px;
}

.sweepstakes_title .sweepstakes_title_unpack::after,
.Sweepstakes_guize_title::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 3px;
  height: 17.5px;
  background: #df0024;
}

.sweepstakes_huida {
  display: block;
  line-height: 15px;
  font-size: 15px;
  margin-top: 10px;
  color: rgb(102, 102, 102);
  font-weight: 500;
  display: inline-block;
  margin-left: 10px;
}

.sweepstakes_guan {
  display: block;
  margin-top: 10px;
  font-size: 16.5px;
  margin-bottom: 10px;
  line-height: 30px;
  font-weight: 500;
}

.sweepstakes_guan input {
  width: 60px;
  height: 30px;
  line-height: 30px;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  text-align: center;
  font-size: 16.5px;
  color: #666;
      overflow: hidden;
}
.unpacking_input {
  width: 60px !important;
}
.sweepstakes_title span {
  font-size: 12.5px;
  display: inline-block;
}

.sweepstakes_title span a {
  color: #0072bb;
}

.sweepstakes_btn {
  display: inline-block;
  width: 100%;
  height: 45px;
  background-color: #d64b4b;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2.5px;
  line-height: 45px;
  text-align: center;
  color: #fff !important;
  font-size: 15px;
}

.Sweepstakes_guize {
  margin-top: 20px;
  text-align: left;
}

.Sweepstakes_guize_content {
  font-size: 15px;
  line-height: 28px;
}

.sweepstakes_content {
  text-align: center;
  position: relative;
}

.Sweepstakes_start {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -37.5px;
  margin-top: -37.5px;
  cursor: pointer;
  width: 75px;
}

.Sweepstakes_dibu {
  width: 100%;
}

.challengeWhellSelect {
  width: 90px;
  height: 25px;
  background: none;
  font-family: "Microsoft YaHei";
  font-size: 15px;
  color: #666;
}

.challengeWhellTitle {
  text-align: center;
  font-size: 14px;
  line-height: 25px;
}

.Sweepstakes_bottom_content {
  line-height: 30px;
}

.Sweepstakes_bottom_content p a {
  text-decoration: underline;
  color: #2a2aca;
}
</style>
