
import { defineComponent, nextTick, onMounted } from "vue";
import $ from "jquery";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import { useRouter } from "vue-router";

import qs from "qs";
export default defineComponent({
  name: "StartJob",

  setup() {
    const imgArr = [
      {
        img: require("../assets/img/start-job/weibo.png"),
        width: "145px",
        top: "-45px",
        left: "-5px",
        text: "美的微波炉",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/integral.png",
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分18888",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/integral.png",
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分8888",
      },
      {
        img: require("../assets/img/start-job/guangpan.png"),
        width: "80px",
        top: "-50px",
        left: "20px",
        text: "伟业计量培训中心课程光盘/个",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/integral.png",
     width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分3888",
      },
      {
        img: require("../assets/img/start-job/lipin.png"),
        width: "120px",
        top: "-78px",
        left: "0",
        text: "伟业数据线",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/coupon.png",
        width: "120px",
        top: "-78px",
        left: "0",
        text: "100元代金券",
      },
      {
        img: "https://www.bzwz.com/static/pc/img/unpacking/integral.png",
       width: "80px",
        top: "-49px",
        left: "24px",
        text: "888积分",
      },
    ];
    let chestCloseTimer: number | undefined;
    const router = useRouter();

    onMounted(() => {
      nextTick(() => {
        // 抽奖
        $("#Q_answer").blur(function () {
          const answer = ($("#Q_answer").val() as any).trim().toLowerCase();
          if (answer == "") {
            Toast({
              title: "要先输入网址才能抽奖哦",
              duration: 2000,
              type: "warning",
            });

            $(this).focus();
            return false;
          }
          if (answer != "bzwz") {
            Toast({
              title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
              type: "warning",
              duration: 2000,
            });

            $(this).focus();
            return false;
          }
        });
        $(".challengeWhellSelect").blur(function () {
          const challengeWhellSelect = $(".challengeWhellSelect").val();
          if (challengeWhellSelect != "bzwz") {
            //layer.msg("选择错误。bzwz是“标准物质”的拼音首字母", { time: 1500 });
            Toast({
              title: "选择错误。bzwz是“标准物质”的拼音首字母",
              type: "warning",
              duration: 2000,
            });

            return false;
          }
        });

        $(".chest-onclose").click(function () {
  
          const token = localStorage.getItem("token");
          if (!token) {
            Modal({
              title: "温馨提示",
              content: "登录后才可以进行抽奖哦~",
              onClose: () => {
                router.push({
                  name: "Login",
                  query: {
                    redirect: router.currentRoute.value.fullPath,
                  },
                });
              },
            });
            return false;
          }
          const answer = ($("#Q_answer").val() as any).trim().toLowerCase();

          const challengeWhellSelect = $(".challengeWhellSelect").val();
          if (answer == "") {
            Toast({
              title: "要先输入网址才能抽奖哦",
              type: "warning",
              duration: 2000,
            });

            $("#Q_answer").focus();
            return false;
          }
          if (answer != "bzwz") {
            Toast({
              title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
              type: "warning",
              duration: 2000,
            });
            $("#Q_answer").focus();
            return false;
          }
          if (challengeWhellSelect != "bzwz") {
            Toast({
              title: "选择错误。bzwz是“标准物质”的拼音首字母",
              type: "warning",
              duration: 2000,
            });
            return false;
          }
          const that = $(".chest-onclose").get(0);

          axios
            .post(
              "/M/Event/StartWork",
              qs.stringify({
                id: 12,
                tag: 1,
              })
            )
            .then((res: any) => {
              if (res.data.success) {
                $(that).addClass("shakecopy");
                let imgUrl = {};
                for (let i = 0; i < imgArr.length; i++) {
                  if (res.data.msg.indexOf(imgArr[i].text) != -1) {
                    imgUrl = imgArr[i];
                  }
                }
                $(".userText").text(res.data.msg);
                $(".chest_zuan").css({
                  width: (imgUrl as any).width,
                  top: (imgUrl as any).top,
                  left: (imgUrl as any).left,
                });
                $(".chest_zuan").attr("src", (imgUrl as any).img);
                that.addEventListener(
                  "webkitAnimationEnd",
                  function () {
                    $(that).closest(".open-has").addClass("opened");
                    setTimeout(function () {
                      $(that).removeClass("show");
                      $(that).closest(".mod-chest").find(".chest-open").addClass("show");
                      setTimeout(function () {
                        $(".chest-open").addClass("blur");
                      }, 500);
                    }, 200);
                  },
                  false
                );
              } else if (res.data.existAddress === false) {
                Modal({
                  title: "提示",
                  content: "系统找不到默认地址，如果没有设置默认地址，请先设置",
                  onConfirm: () => {
                    router.push({
                      name: "addAddress",
                    });
                  },
                });
              } else {
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
            })
            .catch((err) => {
              Toast({
                type: "error",
                title: "执行出错，请联系管理员！",
              });
            });
        });

        $(".close-unpacking-img").click(function () {
          clearTimeout(chestCloseTimer);
          $(".chest-close").removeClass("shakecopy");
          $(".open-has").removeClass("opened");
          $(".chest-open").removeClass("show");
          $(".chest-close").addClass("show");
          $(".chest-open").removeClass("blur");
        });
      });
    });
  },
});
